<template>
  <div>
    <div class="name">
      <div class="mains">
        <div class="titleBg">编辑笔名</div>
        <div class="title">笔名</div>
        <input type="text" v-model="form.Name">
        <div class="ok" @click="setName">提交</div>
      </div>
    </div>
    <load ref="load"></load>
  </div>
</template>

<script>
import {Dialog, Notify, Picker} from "vant";
import load from '../../../components/load'
import { editName } from "@/api/user";

export default {
  data() {
    return {
      form: {},
      StartText: '发送验证码',
      time: 0
    }
  },
  components: {
    [Notify.Component.name]:
    Notify.Component,
    [Picker.name]:
    Picker,
    load
  },
  methods: {
    setName() {
      let Name = this.form.Name;
      if (Name) {
        if (Name.length >= 10) {
          Dialog.alert({
            message: '笔名不能超过10个字',
          });
          return
        }

        let userInfo = JSON.parse(localStorage.getItem("user"));
        let UserID = userInfo.id;

        editName({'id': UserID, 'name': Name}).then(res => {
          if (res.errorCode == 200) {
            Notify({type: 'success', message: '笔名填写成功'});
            // update local storage
            userInfo.Name = Name;
            localStorage.setItem("user", JSON.stringify(userInfo));

            setTimeout(() => {
              this.$router.push('/exhibit');
              
            }, 1000);
          } else {
            Notify({type: 'warning', message: res.errorMsg});
          }
        })
      } else {
        Dialog.alert({
          message: '缺少必填项',
        });
      }
    },
  }
}
</script>

<style scoped lang="scss">
.name {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .mains {
    height: 240px;
    width: 310px;
    background-color: rgba(249, 249, 249, 1);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -155px;
    margin-top: -70px;

    .titleBg {
      width: 100%;
      height: 60px;
      line-height: 60px;
      font-weight: bold;
      text-indent: 2rem;
      font-size: 22px;
    }

    .title {
      width: 245px;
      height: 30px;
      font-size: 13px;
      color: #8D8D8D;
      line-height: 30px;
      margin: 0 auto;
    }

    input {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      border: none;
      width: 250px;
      height: 40px;
      outline: none;
      font-size: 14px;
      text-indent: 1rem;
      padding: 0;
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
      margin-bottom: 15px;
    }

    .code {
      width: 250px;
      height: 35px;
      line-height: 35px;
      background-color: #1989fa;
      color: #FFFFFF;
      text-align: center;
      position: absolute;
      bottom: 140px;
      left: 50%;
      margin-left: -125px;
      font-size: 13px;
      border-radius: 5px;
    }

    .ok {
      width: 250px;
      height: 35px;
      line-height: 35px;
      background-color: #1989fa;
      color: #FFFFFF;
      text-align: center;
      position: absolute;
      bottom: 45px;
      left: 50%;
      margin-left: -125px;
      border-radius: 5px;
    }

  }
}

</style>
