<template>
  <div class="home">
    <Home_Login v-if="Login"></Home_Login>
  </div>

</template>
<script>
import Home_Login from './com/Home_Login'


export default {
  data() {
    return {
      Login: true,
    }
  },
  mounted() {

  },
  methods: {

  },
  components: {
    Home_Login,
  }

}
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;
}

</style>




